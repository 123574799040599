import Vue from 'vue'
import Vuex from 'vuex'
import Request from './RequestManager';
import Notification from './Notification'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    name_cate: "",
    banners: {
      image: ""
    },
    sound:'',
    blog_banner: "",
    media: [],
    category: [],
    categories: {

      think_studio: [ {
          id: "activity_1",
          name: "กิจกรรม"
        }, {
          id: "activity_2",
          name: "แกลอรี่"
        }, {
          id: "activity_3",
          name: "Online Workshop"
        }, {
          id: "activity_4",
          name: "DIY"
        }
      ],
      hot_issue: [{
          id: "culture_1",
          name: "การเมือง และสังคม"
        },{
          id: "culture_2",
          name: "เศรษฐกิจ และการตลาด"
        }, {
          id: "culture_3",
          name: "ศิลปวัฒนธรรม"
        }, {
          id: "culture_4",
          name: "ท่องเที่ยว และสุขภาพ"
        }
      ],

    },
    blogs: [],
    blog_head: ""
  },
  mutations: {
    setMedia (state, data) {
      state.banners = data.banner;
      state.media = data.media;

      state.category = data.category;

      state.category.unshift({
        _id: null,
        name: "ทั้งหมด"
      })
    },
    setCateName (state, name) {
      state.name_cate = name
    },
    blogBanner (state, data) {
      state.blog_banner = process.env.VUE_APP_DOMAIN_IMAGE + data.image;
    },
    setBlog (state, data) {
      state.blogs = data
    },
    setBlogHead (state, name) {
      state.blog_head = name
    },
    setHeaderById (state, data ) {
      for (let i=0;i<state.categories[data.type].length; i++) {
        if ( state.categories[data.type][i].id == data.id) {
          state.blog_head = state.categories[data.type][i].name
        }
      }
    }
  },
  actions: {

    async getNewRelease ({ dispatch }, payload) {
      let res = await dispatch('request/get', { url: 'api/media/new_release' })
      return res;
    },

    async getHistoryList ({ dispatch }, payload ) {
      let res = await dispatch('request/post', { url: 'api/history/list', payload })
      return res;
    },

    async getHistoryCategory ({ dispatch }, id) {
      let res = await dispatch('request/get', { url: 'api/history/category' })
      return res;
    },

    async getProductList ({ dispatch }, id) {
      let res = await dispatch('request/get', { url: 'api/product/web' })
      return res;
    },

    async getMainBanner ({ dispatch }, type) {
      let res = await dispatch('request/post', { url: 'api/banner/bn', payload: { type: type } })
      return res;
    },
    async getPartner ({ dispatch }, id) {
      let res = await dispatch('request/get', { url: 'api/partner' })
      return res;
    },

    async blogType ({ dispatch }, d) {
      let res = await dispatch('request/get', { url: 'api/blog?type='+d.type+'&page='+d.page })
      return res;
    },

    async viewBlog ({ dispatch }, id) {
      let res = await dispatch('request/get', { url: 'api/blog/'+id+'/get' })
      return res;
    },
    async getBlogGroup ({ dispatch, commit }, payload) {
      let res = await dispatch('request/post', { url: 'api/blog/group', payload })
      if (res.success) {
        commit( 'setBlog', res.data )
      }
      return res;
    },

    async getRadio ({ dispatch }, payload) {
      let res = await dispatch('request/get', { url: 'api/radio' })
      return res;
    },
    async _getRadioList ({ dispatch }, payload) {
      let res = await dispatch('request/get', { url: 'api/radiolist' })
      return res;
    },
    async getProduct ({dispatch}, payload) {
      let res = await dispatch('request/get', {url: 'api/product/' + payload+'/find' })
      return res;
    },
    async getMedia ({ dispatch }, payload) {
      let res = await dispatch('request/get', {url: 'api/media/get/' + payload })
      return res;
    },

    async getCategoryPage ({ dispatch, commit }, payload) {

      commit('setCateName', payload.name)
      let res = await dispatch('request/post', {url: 'api/category/type', payload: payload })

      if (res.success) {
        commit('setMedia', res.data);
      }

    },


    async getBlogHomepage ({dispatch}, payload) {
      let res = await dispatch('request/get', {url: 'api/blog/home'})
      return res;
    },

    async getHomepage ({dispatch}, payload) {
      let res = await dispatch('request/get', {url: 'api/media/homepage'})
      return res;
    },
    async getPreview ({dispatch}, payload) {
      let res = await dispatch('request/get', {url: 'api/media/preview' })
      return res;
    },

    async getSales ({dispatch}, payload) {
      const res = await dispatch('request/get', {url: 'api/sales' })
      return res;
    }

  },
  modules: {
    request: Request,
    notif: Notification

  }
}
