import Vue from 'vue'
import Vuex from 'vuex'
import router from './../../router'

Vue.use(Vuex)

/** Module */
import Request from './RequestManager';

export default {
  namespaced: true,
  state: {
    authen: localStorage.getItem('access_token') ? true: false,
    role: localStorage.getItem('role') ? localStorage.getItem('role'): false,
    fullname: localStorage.getItem('fullname') ? localStorage.getItem('fullname'): null,
    avatar: localStorage.getItem('avatar') ? localStorage.getItem('avatar'): null,
    email: localStorage.getItem('email') ? localStorage.getItem('email'): null
  },
  mutations: {

    updateFullnameEmail  (state, data) {

      state.role = data.role;
      state.email = data.email;
      state.fullname = data.fullname;

    },

    updateAuthen (state, authen) {

      state.authen = authen.auth;
      state.role = authen.role;
      
      if ( !authen.auth && !authen.role ) {

        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
      }
      
    }
  },
  actions: {
    async getAccessToken ({ dispatch }) {
      
      let payloads = {
        refresh_token: localStorage.getItem('refresh_token')
      }

      let res = await dispatch('request/post', { url:'oauth/token', payload: payloads} );

      localStorage.access_token = res.access_token;
      localStorage.refresh_token = res.refresh_token;
      
    },
    async Login ({ commit, dispatch }, payload) {

      let payloads = {
        email: payload.email,
        password: payload.password
      }

      let res = await dispatch('request/post', { url:'login', payload: payloads} );

      if ( !res.success ) 
        return;

      localStorage.access_token = res.access_token;
      localStorage.refresh_token = res.refresh_token;
      localStorage.role = res.role;
      localStorage.email = payload.email;
      localStorage.fullname = res.fullname;

      commit('updateFullnameEmail', { email: payload.email, fullname: res.fullname, role: res.role})
      
      await commit('updateAuthen', { auth: true, role: res.role })

      return res;

    },
    async Signup ({ dispatch }, payloads ) {

      let res = await dispatch('request/post', {url: 'api/signup', payload: payloads });
      if ( res.success )
        return router.push({'path': '/register/success'})
      
      return res;
    
    },
    async resetAuthData ({ commit }, tmp) {

      console.log('in reset auth');
      
      await commit('updateAuthen', { auth: false, role: false })
      
    },
    async Logout ({ commit, dispatch }) {

      let res = await dispatch('request/get', {url:'logout'});

      if (res.success ) {
        dispatch('resetAuthData');
        return router.push({'path': '/'})
      }

      return res;

    },
    async ForgetPassword ({ dispatch }, payloads) {
      let res = await dispatch('request/post', { url:'api/password/create', payload: payloads} );
    }
    
  },

  modules: {
    request: Request
  }
  
}
