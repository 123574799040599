import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import Notification from './Notification'
import router from './../../router'

var rootUrl = process.env.VUE_APP_DOMAIN_API;


export default {
  namespaced: true,
  actions: {
    
    async get ({dispatch}, arg) {
      arg.method = 'get';
      return await dispatch('queryString', arg)
    },

    async post ({ dispatch }, arg ) {
      arg.method = 'post';
      return await dispatch('formData', arg)
    },

    async put ({ dispatch }, arg ) {
      arg.method = 'put';
      return await dispatch('formData', arg)
    },

    async delete ({ dispatch }, arg ) {
      arg.method = 'delete';
      return await dispatch('queryString', arg)
    },

    async formData ({dispatch}, arg) {

      try{

        /** Setup Data */
        let headers  = arg.headers || {};
        let url = rootUrl+arg.url
        let callApi;

        /** Push JWT Token */
  

        if (localStorage.getItem("access_token") === null) {
          
        }else {
          headers['Authorization'] = "Bearer " + localStorage.getItem('access_token');
        }

        callApi = await Vue.http[arg.method]( url, arg.payload, {
          headers: headers
        });

        return callApi.body;

      } catch (error) {

        let errorMessge = await dispatch('handleError', error)

        if (error.status == 401) {
          return router.push({'path': '/'})
        } else {
          dispatch('notif/error', errorMessge )
        }

        return false;
      }

      
    },

    async queryString ({ dispatch }, arg) {

      try {
        
        /** Setup Data */
        let headers = arg.headers || {};
        let url = rootUrl+arg.url
        let callApi;

        /** Push JWT Token */
        if ( localStorage.getItem('access_token') )
          headers['Authorization'] = "Bearer " + localStorage.getItem('access_token');
      
        callApi = await Vue.http[arg.method]( url, {
          headers: headers
        });
        return callApi.body;

      } catch (error) {
        
        let errorMessge = await dispatch('handleError', error)

        if (error.status == 401) {
          return router.push({'path': '/'})
        } else {
          dispatch('notif/error', errorMessge )
        }

        return false;
      }
  
    },

    async handleError ({ dispatch }, error ) {

      let status_code = error.status;

      switch (status_code) { 
        case 400:	
          return error.body.message;  // Validate error
        case 401:	
        console.log(401);
          await dispatch('auth/resetAuthData', "", {root:true});
          return 'Authorization credentials required'; // ยังไม่ได้ Login
        case 403:	
          return 'Invalid access rights'; // Login แล้วแต่ไม่มีสิทธิ์เข้าถึงข้อมูล
        case 404:
          return 'Invalid Api router'
        case 405:	
          return 'Wrong http method requested on endpoint';
        case 415:	
          return 'Unsupported content type defined'; 
        case 500:	
          return 'Generic server side error';
        case 501:	
          return 'Current channel is not supported'; 
        case 502:	
          return 'Invalid response from upstream server'; 
        case 503:	
          return 'Server is currently unavailable because traffic overload or it is down for maintenance'; 
        case 504:	
          return 'API Request Timeout';
        default:
          return 'Error, Please contact the system support.';
      }

    },
    
  },
  modules: {
    notif: Notification,
  }
}
