import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const options = {
  group: 'notif',
  duration: 3000,
}

export default {
  namespaced: true,
  actions: {

    success ({ dispatch }, text) {
      let payload = {
        type: 'success',
        text: text,
        title: 'Successfully',
        icon: '<i class="fas fa-check-circle"></i>'
      }
      dispatch('notif', payload)
    },

    info ({ dispatch }, text) {
      let payload = {
        type: 'info',
        text: text,
        title: 'Information',
        icon: '<i class="fas fa-bookmark"></i>'
      }
      dispatch('notif', payload)
    },

    warning ({ dispatch }, text) {
      let payload = {
        type: 'warn',
        text: text,
        title: 'Warning',
        icon: '<i class="fas fa-exclamation-triangle"></i>'
      }
      dispatch('notif', payload)
    },
    
    error ({ dispatch }, text) {
      let payload = {
        type: 'error',
        text: text,
        title: 'Error',
        icon: '<i class="fas fa-times"></i>'
      }
      dispatch('notif', payload)
    },

    notif ({}, arg) {
      Vue.notify({
        ...options,
        title: arg.icon + ' ' + arg.title,
        type: arg.type,
        text: arg.text,
      })
    }
    
  },
}
