import Vue from 'vue'
import Vuex from 'vuex'
import Auth from './module/Auth';
import User from './module/User';
import Notification from './module/Notification'
import Website from './module/Website'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth: Auth,
    user: User,
    notification: Notification,
    website: Website
  }
})
