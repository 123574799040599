import Vue from 'vue'
import Vuex from 'vuex'
import router from '../../router'
import Request from './RequestManager';
import Notification from './Notification'
import Authen from './Auth'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    users: [],
    paginate: {
      limit_page: 1,
      total_item: 0,
      total_page: 1,
      current_page: 1,
    },
  },
  mutations: {
    updateUsers(state, obj) {
      state.users = obj
    },
    updatePagePaginate (state, page) {
      state.paginate.current_page = page;
    },
    updatePaginate (state, obj) {
      state.paginate.limit_page = obj.limit_page;
      state.paginate.total_item = obj.total_item;
      state.paginate.total_page = obj.total_page;
      state.paginate.current_page = obj.current_page;

    },
  },
  actions: {

    async updateAvatar ({dispatch}, payload) {

      let res = await dispatch('request/post', { url: 'api/user/avatar' , payload: payload })
      
      if ( res.success ) {
        dispatch('notif/success', res.message )
      }
      
      return res;

    },

    async createAccount ({dispatch}, payload) {

      let res = await dispatch('request/post', { url: 'api/user' , payload: payload })
      if ( res.success ) {
        dispatch('notif/success', res.message )
      }
      return res;
    },

    async setPermission ({dispatch}, payload) {
      let res = await dispatch('request/put', { url: 'api/permission/'+ payload.user_id , payload: payload })
      if ( res.success )
        dispatch('notif/success', res.message )
      return res;
    },
    async getProfile ({dispatch}) {

      let res = await dispatch('request/get', { url: 'api/user/profile/detail'})
      return res;

    },
    async getUserDetail ({dispatch}, payload) {

      let res = await dispatch('request/get', { url: 'api/user/' + payload.user_id})
      return res;
    },
    async getUserListing ({dispatch, commit}, page = 1) {
      let res = await dispatch('request/get', { url: 'api/user/list/'+page });
      await commit('updateUsers', res.data.items)
      commit('updatePaginate', {
        limit_page: res.data.limit_page,
        total_item: res.data.total_item,
        total_page: res.data.total_page,
        current_page: res.data.current_page
      });
    },

    async updateProfileUser ({dispatch}, payload) {
      let res = await dispatch('request/put', { url: 'api/user/'+ payload.id , payload: payload })
      if ( res.success ) {
        dispatch('notif/success', res.message )
      }
      return res;
    },

    async showUser ({dispatch}, id) {
      let res = await dispatch('request/get', { url: 'api/admin/user/' + id })
      return res.data;
    },

    async updatePassword ({ dispatch }, payload) {

      let res = await dispatch('request/post', {url: 'api/user/password/change', payload: payload})
      if ( res.success ) {
        dispatch('notif/success', res.message )
      }
      return res;

    },
    async changePassword ({ dispatch }, payload){

      let res = await dispatch('request/post', {url: 'api/user/changepassword', payload: payload})
      return res;

    },
 
    async updateProfile ({ dispatch }, payload) {
      
      if ( localStorage.role == 'admin' || localStorage.role == 'office') {
        
        let res = await dispatch('request/put', {url: 'api/admin/profile', payload: payload});
        return res.data;
      } else {
        let res = await dispatch('request/put', {url: 'api/user/'+payload.id, payload: payload});
        return res.data;
      }

    },

    async forgetPassword ({dispatch}, payload ) {
      let res = await dispatch('request/post', {url: 'api/password/create', payload: payload})
      return res.data;
    },

    async FindDetailToReset ({dispatch}, payload) {
      let res = await dispatch('request/get', {url: 'api/password/find/'+payload.token})
      return res;
    },

    async confirmReset ({dispatch}, payload) {
      let res = await dispatch('request/post', {url: 'api/password/reset', payload})
      return res;
    }
 
  },
  modules: {
    request: Request,
    notif: Notification,
    auth: Authen
    
  }
}
